import React, { useState } from "react";
import { Button, Dialog, DialogProps } from "@mui/material";
import {
  MigrationVulnerabilityButtonsContainer,
  MigrationVulnerabilityCancelButton,
  MigrationVulnerabilityContent,
  MigrationVulnerabilityContentColumn,
  MigrationVulnerabilityFromsContainer,
  MigrationVulnerabilityTextField,
} from "./style";
import { useMigrationVulnerabilityContext } from "../../context/vulnerability-areas";
import { useTranslations } from "@/utils/translations";
import { PasteVulnerabilityConfigModal } from "@/features/analysis/migrations/components/vulnerability/components/import-config";

type MigrationAreaVulnerabilityModalProps = {
  onCancelClick: () => void;
} & Partial<DialogProps>;
export const MigrationAreaVulnerabilityModal = ({
  onCancelClick,
}: MigrationAreaVulnerabilityModalProps) => {
  const strings = useTranslations();
  const { form } = useMigrationVulnerabilityContext();
  const [openImport, setOpenImport] = useState(false);
  if (!form) return null;

  const { values, setFieldValue } = form;
  return (
    <Dialog open={true}>
      <MigrationVulnerabilityContent>
        <MigrationVulnerabilityFromsContainer>
          <MigrationVulnerabilityContentColumn>
            Windfarm config
            <MigrationVulnerabilityTextField
              value={values.blades}
              error={!!form?.getFieldMeta("blades").error}
              helperText={form?.getFieldMeta("blades").error}
              label={"blades"}
              onChange={({ target: { value } }) => {
                setFieldValue("blades", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.chord}
              label={"chord"}
              error={!!form.getFieldMeta("chord").error}
              helperText={form.getFieldMeta("chord").error}
              onChange={({ target: { value } }) => {
                setFieldValue("chord", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.numberOfTurbines}
              label={"numberOfTurbines"}
              error={!!form.getFieldMeta("numberOfTurbines").error}
              helperText={form.getFieldMeta("numberOfTurbines").error}
              onChange={({ target: { value } }) => {
                setFieldValue("numberOfTurbines", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.omega}
              label={"omega"}
              error={!!form.getFieldMeta("omega").error}
              helperText={form.getFieldMeta("omega").error}
              onChange={({ target: { value } }) => {
                setFieldValue("omega", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.pitch}
              label={"pitch"}
              error={!!form.getFieldMeta("pitch").error}
              helperText={form.getFieldMeta("pitch").error}
              onChange={({ target: { value } }) => {
                setFieldValue("pitch", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.radius}
              label={"radius"}
              error={!!form.getFieldMeta("radius").error}
              helperText={form.getFieldMeta("radius").error}
              onChange={({ target: { value } }) => {
                setFieldValue("radius", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.bladeFormRadiusArray}
              label={"bladeFormRadiusArray"}
              error={!!form.getFieldMeta("bladeFormRadiusArray").error}
              helperText={form.getFieldMeta("bladeFormRadiusArray").error}
              onChange={({ target: { value: val } }) => {
                const res = val.split(",").map((it) => it.trim());
                setFieldValue(
                  "bladeFormRadiusArray",
                  res.filter((it) => it).length ? res : []
                );
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.bladeFormChordsArray}
              label={"bladeFormChordsArray"}
              error={!!form.getFieldMeta("bladeFormChordsArray").error}
              helperText={form.getFieldMeta("bladeFormChordsArray").error}
              onChange={({ target: { value: val } }) => {
                const res = val.split(",").map((it) => it.trim());
                setFieldValue(
                  "bladeFormChordsArray",
                  res.filter((it) => it).length ? res : []
                );
              }}
            />
            Windfarm instance config
            <MigrationVulnerabilityTextField
              value={values.hubheight}
              label={"hubheight"}
              error={!!form.getFieldMeta("hubheight").error}
              helperText={form.getFieldMeta("hubheight").error}
              onChange={({ target: { value } }) => {
                setFieldValue("hubheight", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.offset}
              label={"offset"}
              error={!!form.getFieldMeta("offset").error}
              helperText={form.getFieldMeta("offset").error}
              onChange={({ target: { value } }) => {
                setFieldValue("offset", value);
              }}
            />
            {/*<MigrationVulnerabilityTextField*/}
            {/*  value={values.timeOfWorkPerMonth}*/}
            {/*  label={"timeOfWorkPerMonth"}*/}
            {/*  error={!!form.getFieldMeta("timeOfWorkPerMonth").error}*/}
            {/*  helperText={form.getFieldMeta("timeOfWorkPerMonth").error}*/}
            {/*  onChange={({ target: { value: val } }) => {*/}
            {/*    const res = val.split(",").map((it) => it.trim());*/}
            {/*    setFieldValue(*/}
            {/*      "timeOfWorkPerMonth",*/}
            {/*      res.filter((it) => it).length ? res : undefined*/}
            {/*    );*/}
            {/*  }}*/}
            {/*/>*/}
          </MigrationVulnerabilityContentColumn>
          <MigrationVulnerabilityContentColumn>
            bird config
            <MigrationVulnerabilityTextField
              value={values.wingspan}
              label={"wingspan"}
              error={!!form.getFieldMeta("wingspan").error}
              helperText={form.getFieldMeta("wingspan").error}
              onChange={({ target: { value } }) => {
                setFieldValue("wingspan", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.length}
              label={"length"}
              error={!!form.getFieldMeta("length").error}
              helperText={form.getFieldMeta("length").error}
              onChange={({ target: { value } }) => {
                setFieldValue("length", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.speed}
              label={"speed"}
              error={!!form.getFieldMeta("speed").error}
              helperText={form.getFieldMeta("speed").error}
              onChange={({ target: { value } }) => {
                setFieldValue("speed", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.flight_type}
              label={"flight_type"}
              error={!!form.getFieldMeta("flight_type").error}
              helperText={form.getFieldMeta("flight_type").error}
              onChange={({ target: { value } }) => {
                setFieldValue("flight_type", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.updownProportion}
              label={"updownProportion"}
              error={!!form.getFieldMeta("updownProportion").error}
              helperText={form.getFieldMeta("updownProportion").error}
              onChange={({ target: { value } }) => {
                setFieldValue("updownProportion", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.nocturnalActivity}
              label={"nocturnalActivity"}
              error={!!form.getFieldMeta("nocturnalActivity").error}
              helperText={form.getFieldMeta("nocturnalActivity").error}
              onChange={({ target: { value } }) => {
                setFieldValue("nocturnalActivity", value);
              }}
            />
            Integration Settings
            <MigrationVulnerabilityTextField
              value={values.xinc}
              label={"xinc"}
              error={!!form.getFieldMeta("xinc").error}
              helperText={form.getFieldMeta("xinc").error}
              onChange={({ target: { value } }) => {
                setFieldValue("xinc", value);
              }}
            />
            <MigrationVulnerabilityTextField
              value={values.yinc}
              label={"yinc"}
              error={!!form.getFieldMeta("yinc").error}
              helperText={form.getFieldMeta("yinc").error}
              onChange={({ target: { value } }) => {
                setFieldValue("yinc", value);
              }}
            />
          </MigrationVulnerabilityContentColumn>
        </MigrationVulnerabilityFromsContainer>
        <MigrationVulnerabilityButtonsContainer>
          <MigrationVulnerabilityCancelButton onClick={onCancelClick}>
            Ok
          </MigrationVulnerabilityCancelButton>
        </MigrationVulnerabilityButtonsContainer>
        <MigrationVulnerabilityButtonsContainer>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(form.values));
            }}
          >
            {strings["data-extraction.copy-config"]}
          </Button>
          <Button
            onClick={() => {
              setOpenImport(true);
            }}
          >
            {strings["data-extraction.import-config"]}
          </Button>
          {openImport && (
            <PasteVulnerabilityConfigModal
              onClose={() => setOpenImport(false)}
              open={openImport}
              onSubmit={(data) => {
                form.setValues(data);
                // formik.setFieldValue("scripts", data.scripts);
                setOpenImport(false);
              }}
            />
          )}
        </MigrationVulnerabilityButtonsContainer>
      </MigrationVulnerabilityContent>
    </Dialog>
  );
};
