import {
  createContext,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFormik } from "formik";
import { IndexedArea } from "@rrrcn/common-types/services/api/migrations/generate-tracks/response";
import { _useSendAnalysis } from "../../common/utils";
import { vulnerabilityFormToRequest } from "../components/vulnerability/utils/form-to-request";
import { VulnerabilitySchema } from "../components/vulnerability/utils/schema";
import { useGeneratedTracks } from "../components/generate-tracks/hooks/use-generated-tracks";
export type VulnerabilityFormType = {
  speed: number;
  length: number;
  wingspan: number;
  nocturnalActivity: number;
  flight_type: "flapping" | "gliding";

  blades: number;
  radius: number;
  pitch: number;
  chord: number;
  bladeFormRadiusArray: number[];
  bladeFormChordsArray: number[];
  omega: number;
  numberOfTurbines: number;

  hubheight: number;
  offset: number;
  timeOfWorkPerMonth?: number[];

  xinc: number;
  yinc: number;
  updownProportion: number;
};
export type MigrationVulnerabilityContextType = {
  selectedAreas: (IndexedArea & { index: number })[];
  setSelectedAreas: React.Dispatch<
    SetStateAction<(IndexedArea & { index: number })[]>
  >;
  form?: ReturnType<typeof useFormik<Partial<VulnerabilityFormType>>>;
} & Partial<ReturnType<typeof _useSendAnalysis>>;
const MigrationVulnerabilityContext =
  createContext<MigrationVulnerabilityContextType>({
    selectedAreas: [],
    setSelectedAreas: () => {},
    form: undefined,
  });
export const useMigrationVulnerabilityContextValue =
  (): MigrationVulnerabilityContextType => {
    const { onSend, ...sendAnalysisReturn } = _useSendAnalysis("vulnerability");
    const { tracks } = useGeneratedTracks();

    const [selectedAreas, setSelectedAreas] = useState<any[]>([]); // TODO change to Set
    useEffect(() => {
      setSelectedAreas([]);
    }, [tracks]);
    const vulnerabilityConfigForm = useFormik<Partial<VulnerabilityFormType>>({
      validationSchema: VulnerabilitySchema,
      onSubmit: (data) => {
        const formToSend = vulnerabilityFormToRequest(
          data as Required<VulnerabilityFormType>,
          selectedAreas
        );
        console.log({ formToSend });
        onSend(
          vulnerabilityFormToRequest(
            data as Required<VulnerabilityFormType>,
            selectedAreas
          )
        );
      },
      initialValues: {
        xinc: 0.05,
        yinc: 0.05,
        updownProportion: 0.5,
        bladeFormChordsArray: [],
        bladeFormRadiusArray: [],
        flight_type: "flapping",
      },
      validateOnChange: false,
    });
    return {
      selectedAreas,
      form: vulnerabilityConfigForm,
      setSelectedAreas,
      ...sendAnalysisReturn,
    };
  };
export const MigrationVulnerabilityContextProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: MigrationVulnerabilityContextType }>) => {
  return (
    <MigrationVulnerabilityContext.Provider value={value}>
      {children}
    </MigrationVulnerabilityContext.Provider>
  );
};
export const useMigrationVulnerabilityContext = () => {
  const { selectedAreas, form, setSelectedAreas, ...context } = useContext(
    MigrationVulnerabilityContext
  );
  const toggleSelectedArea = useCallback(
    (area: IndexedArea & { index: number }) => {
      setSelectedAreas((prev) => {
        const isChecked = prev.find((it) => it.index === area.index);
        return isChecked
          ? prev.filter((it) => it.index !== area.index)
          : [...prev, area];
      });
    },
    []
  );
  return {
    selectedAreas,
    form,
    setSelectedAreas,
    toggleSelectedArea,
    ...context,
  };
};
