import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogProps, TextField } from "@mui/material";
import { FormikConfig } from "formik/dist/types";
import { VulnerabilityFormType } from "@/features/analysis/migrations/context/vulnerability-areas";
import { VulnerabilityPartialSchema } from "@/features/analysis/migrations/components/vulnerability/utils/schema";

export const PasteVulnerabilityConfigModal = ({
  onSubmit,
  ...props
}: Omit<DialogProps, "onSubmit"> & {
  onSubmit: FormikConfig<Partial<VulnerabilityFormType>>["onSubmit"];
}) => {
  const form = useFormik<Partial<VulnerabilityFormType>>({
    initialValues: {},
    onSubmit: onSubmit,
    validationSchema: VulnerabilityPartialSchema,
  });
  const [text, setText] = useState("");
  useEffect(() => {
    if (text) {
      try {
        form.setValues(JSON.parse(text));
      } catch (e) {
        form.setValues({});
      }
    }
  }, [text]);
  useEffect(() => {
    return form.resetForm();
  }, []);
  return (
    <Dialog {...props}>
      <TextField
        error={!!form.errors}
        multiline={true}
        value={text}
        onChange={({ currentTarget: { value } }) => setText(value)}
      />
      <Button onClick={form.submitForm}>Save</Button>
    </Dialog>
  );
};
