import { VulnerabilityFormType } from "../../../context/vulnerability-areas";
import { IndexedArea } from "@rrrcn/common-types/services/api/migrations/generate-tracks/response";
import { area, bboxPolygon } from "@turf/turf";
import { ServicesVulnerabilityApiTypes } from "@rrrcn/common-types/services/api/vulnerability";

export const vulnerabilityFormToRequest = (
  form: Required<VulnerabilityFormType>,
  areas: IndexedArea[]
): ServicesVulnerabilityApiTypes.OverallMultipleAreaVulnerability.Body => {
  const {
    bladeFormRadiusArray,
    flight_type,
    bladeFormChordsArray,
    blades,
    chord,
    hubheight,
    length,
    nocturnalActivity,
    numberOfTurbines,
    offset,
    omega,
    pitch,
    radius,
    speed,
    yinc,
    xinc,
    updownProportion,

    timeOfWorkPerMonth,
    wingspan,
  } = form;
  const areasConfig: ServicesVulnerabilityApiTypes.OverallMultipleAreaVulnerability.Body["areas"] =
    areas.flatMap((it) => {
      const altitudesValues = Object.keys(it.altitudeStatistics)
        .map((it) => Number(it))
        .filter((it) => it !== undefined && String(it) !== "NaN")
        .sort((a, b) => Math.sign(a - b));
      console.log(it.altitudeStatistics, altitudesValues);
      const totalAltitudes = Object.values(it.altitudeStatistics).reduce(
        (acc, it) => acc + Number(it),
        0
      );
      const totalFlights = Object.values(it.altitudeStatistics).reduce(
        (a, b) => a + b,
        0
      );
      if (!totalAltitudes) return [];
      const rotorInterval = [hubheight - radius, hubheight + radius];
      const percentAtRotorHeight =
        altitudesValues.filter(
          (it) => it <= rotorInterval[1] && it >= rotorInterval[0]
        ).length / totalAltitudes;
      const areaArea = area(bboxPolygon(it.area)) / (1000 * 1000);

      return [
        {
          id: it.id,
          area: bboxPolygon(it.area),
          percentAtRotorHeight,
          birdDensitiesPerMonth: new Array(12)
            .fill(0)
            .map(
              (_, month) =>
                (it.probabilities.months[month] || 0) / it.probabilities.total
            )
            .map((monthProb) => (monthProb * totalFlights) / areaArea),
          flightheights: totalAltitudes
            ? new Array(Math.max(...altitudesValues))
                .fill(0)
                .map((__, index) => [
                  index,
                  (it.altitudeStatistics[index] || 0) / totalAltitudes,
                ])
            : [],
        },
      ];
    });
  const bladeForm = bladeFormRadiusArray.map(
    (it, index) => [it, bladeFormChordsArray[index]] as [number, number]
  );
  return {
    areas: areasConfig,
    yinc,
    xinc,
    windfarmInstanceConfig: {
      timeOfWorkPerMonth,
      offset,
      hubheight,
    },
    windfarmConfig: {
      radius,
      blades,
      chord,
      omega,
      numberOfTurbines,
      pitch,
      bladeForm,
    },
    updownProportion,
    birdConfig: {
      wingspan,
      flight_type,
      length,
      speed,
      nocturnalActivity,
    },
  };
};
